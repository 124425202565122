import React from 'react'

function Footer() {
  return (
    <div className='foteer'>
        <p>contact with us </p>
        <p>Twitter @TuwaiqAcademy</p>
    </div>
  )
}

export default Footer