import React from 'react'

function Courses101() {
  return (
    <div className='link'>
      <a href="https://satr.codes/">منصة سطر</a>
    </div>
  );
}

export default Courses101